import React from 'react'
import Slider from "react-slick"
import advertisement01 from '../assets/images/advertisement/01.jpg'
import advertisement02 from '../assets/images/advertisement/02.jpg'
import advertisement03 from '../assets/images/advertisement/03.jpg'
import advertisement04 from '../assets/images/advertisement/04.jpg'
import advertisement05 from '../assets/images/advertisement/05.jpg'
import advertisement06 from '../assets/images/advertisement/06.jpg'

import "slick-carousel/slick/slick.css";

const SimpleSlider3 = ({children}) => {
    const settings = {
        dots: false,
        fade: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000
    };
    const style = {
        margin: 0,
        background: "#707070"
    };  
    return(
      <Slider {...settings}>
        <div class="portrait">
          <p class="photo_title">kyazm - fickle friends</p>
          <img src={advertisement01} alt="image"/>
        </div>
        <div class="portrait">
          <p class="photo_title">phai - Arkroyal(feat.gato)</p>
          <img src={advertisement02} alt="image"/>
        </div>
        <div class="portrait">
          <p class="photo_title">phai - Fade</p>
          <img src={advertisement03} alt="image"/>
        </div>
        <div class="portrait">
          <p class="photo_title">phai - Of course, but I’m not mary jane(feat.MINAKEKKE)</p>
          <img src={advertisement04} alt="image"/>
        </div>
        <div class="portrait">
          <p class="photo_title">あの日々の話</p>
          <img src={advertisement05} alt="image"/>
        </div>
        <div class="portrait">
          <p class="photo_title">ヴィニルと烏</p>
          <img src={advertisement06} alt="image"/>
        </div>
      </Slider>
    )
}

export default SimpleSlider3