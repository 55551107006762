import React from 'react'
import Layout from '../components/layout'
import Helmet from 'react-helmet'
import Adnav from '../components/Adnav'

import SimpleSlider3 from '../components/AdSlider'


const Advertisement = (props) => {
  const siteTitle = 'Onodera Ryo  | portrait'

  return(
    <Layout>
      <Helmet>
        <title>{siteTitle}</title>
      </Helmet>
      <div id="main">
        <div class="portrait">
          <Adnav>
            Advertisement
          </Adnav>
          <SimpleSlider3 />
        </div>
      </div>
    </Layout>
  )
}

export default Advertisement